const route = [
  'Botim',
  'Botim2',
  'BEABEE',
  'KOALA4D',
  'VSmedia',
  'Sahem',
  // 'HEMAM',
  'KOALAD4D2',
  'ATLANTIC',
  'MEPI',
  'LOGO',
  'MASS',
  'RASHEED',
  'ShammetZout',
  // 'Ummat',
  'HASADSchool',
  'Nasheed',
  'Thinking',
  'Dawaii',
  // 'STORY',
];

export default route;
