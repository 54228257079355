import {
    SiHoudini, SiCinema4D, SiAdobeaftereffects,
    SiAdobepremierepro,SiAdobephotoshop,SiAdobeillustrator
} from 'react-icons/si'

const icon = [<SiHoudini />, <SiCinema4D />,
    <SiAdobeaftereffects />, <SiAdobepremierepro />,
    <SiAdobephotoshop />,<SiAdobeillustrator/>]


export default icon;