import img1 from '../assets/Page_1/T_1.jpg';
import img2 from '../assets/Page_1/T_2.jpg';
import img3 from '../assets/Page_1/T_3.jpg';
import img4 from '../assets/Page_1/T_4.jpg';
import img5 from '../assets/Page_1/T_5.jpg';
import img6 from '../assets/Page_1/T_6.jpg';
import img7 from '../assets/Page_1/T_7.jpg';
import img8 from '../assets/Page_1/T_8.jpg';
import img9 from '../assets/Page_1/T_9.jpg';
import img10 from '../assets/Page_1/T_10.jpg';
import img11 from '../assets/Page_1/T_11.jpg';
import img12 from '../assets/Page_1/T_12.jpg';
import img13 from '../assets/Page_1/T_13.jpg';
import img14 from '../assets/Page_1/T_14.jpg';
import img15 from '../assets/Page_1/T_15.jpg';
import img16 from '../assets/Page_1/T_16.jpg';
import img17 from '../assets/Page_1/T_17.jpg';
import img18 from '../assets/Page_1/T_18.jpg';
import img19 from '../assets/Page_1/T_19.jpg';
import img20 from '../assets/Page_1/T_20.jpg';

export default [
  img1,
  img2,
  img3,
  img4,
  img5,
  img6,
  //   img7,
  img8,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
  //   img15,
  img16,
  img17,
  img18,
  img19,
  //   img20,
];
