const text = [
  'Botim',
  'Botim',
  'BEABEE',
  'KOALA4D',
  'Personal Project ',
  'Sahem',
  'HEMAM',
  'KOALAD4D',
  'ATLANTIC',
  'MEPI',
  'LOGO',
  'MASS',
  'RASHEED',
  'UMMAT WAQF',
  'UMMAT WAQF',
  'HASAD School',
  'UMMAT WAQF',
  'Personal Project',
  'Personal Project',
  'STORY',
];

export default text;
